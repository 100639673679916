// import crypto from "crypto";

// const algorithm = "aes-256-ctr";
// const iv = crypto.randomBytes(16);

// export function encrypt(text: string): string {
//   let secretKey: string;

//   if (process.env.NEXT_PUBLIC_PARAMS_TOKEN !== undefined) {
//     secretKey = process.env.NEXT_PUBLIC_PARAMS_TOKEN;
//   } else {
//     throw new Error("PARAMS_TOKEN is not defined");
//   }
//   const cipher = crypto.createCipheriv(algorithm, secretKey, iv);

//   const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);

//   return encodeURIComponent(`${iv.toString("hex")}:${encrypted.toString("hex")}`);
// }

// export function decrypt(hash: string): string {
//   let secretKey: string;

//   if (process.env.NEXT_PUBLIC_PARAMS_TOKEN !== undefined) {
//     secretKey = process.env.NEXT_PUBLIC_PARAMS_TOKEN;
//   } else {
//     throw new Error("PARAMS_TOKEN is not defined");
//   }
//   const parts = decodeURIComponent(hash).split(":");

//   const decipher = crypto.createDecipheriv(
//     algorithm,
//     secretKey,
//     Buffer.from(parts.shift() as string, "hex"),
//   );

//   const decrpyted = Buffer.concat([
//     decipher.update(Buffer.from(parts.join(":"), "hex")),
//     decipher.final(),
//   ]);

//   return decrpyted.toString();
// }

import crypto from "crypto";

const algorithm = "aes-256-ctr";
const iv = crypto.randomBytes(16);

function toBase64Url(base64: string): string {
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

function fromBase64Url(base64Url: string): string {
  base64Url = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  while (base64Url.length % 4) {
    base64Url += '=';
  }
  return base64Url;
}

export function encrypt(text: string): string {
  let secretKey: string;

  if (process.env.NEXT_PUBLIC_PARAMS_TOKEN !== undefined) {
    secretKey = process.env.NEXT_PUBLIC_PARAMS_TOKEN;
  } else {
    throw new Error("PARAMS_TOKEN is not defined");
  }

  const cipher = crypto.createCipheriv(algorithm, secretKey, iv);

  const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);

  return `${toBase64Url(iv.toString("base64"))}:${toBase64Url(encrypted.toString("base64"))}`;
}

export function decrypt(hash: string): string {
  let secretKey: string;

  if (process.env.NEXT_PUBLIC_PARAMS_TOKEN !== undefined) {
    secretKey = process.env.NEXT_PUBLIC_PARAMS_TOKEN;
  } else {
    throw new Error("PARAMS_TOKEN is not defined");
  }

  const parts = hash.split(":");

  const decipher = crypto.createDecipheriv(
    algorithm,
    secretKey,
    Buffer.from(fromBase64Url(parts.shift() as string), "base64"),
  );

  const decrypted = Buffer.concat([
    decipher.update(Buffer.from(fromBase64Url(parts.join(":")), "base64")),
    decipher.final(),
  ]);

  return decrypted.toString();
}
