import PaymentAmount from "../components/charge/paymentAmount";

export default function Home() {
  return (
    <>
      <main>
        <div>
          <PaymentAmount />
        </div>
      </main>
    </>
  );
}
