import {Button} from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import * as React from "react";

import {Icons} from "../icons";

interface RemotePaymentProps {
  remoteUrl: string;
  remotePaymentStore: string;
}

export default function RemotePayment(props: RemotePaymentProps) {
  const [hasCopied, setHasCopied] = React.useState(false);

  async function copyToClipboard(remoteUrl: string) {
    const textToCopy = `Você deve clicar no link através do navegador do seu celular. A seguir será criada a chave Pix, que você deverá copiar e colar no app do seu banco para efetuar o pagamento para: ${props.remotePaymentStore}\n\n${remoteUrl}`;

    await navigator.clipboard.writeText(textToCopy);
  }

  React.useEffect(() => {
    setTimeout(() => {
      setHasCopied(false);
    }, 3000);
  }, [hasCopied]);

  return (
    <Grid
      item
      md={12}
      sx={{
        display: "flex",
        justifyContent: {md: "center", xs: "center"},
        width: {md: "50%", xs: "100%"},
      }}
      xs={12}
    >
      <Grid>
        <Container>
          <Button
            endIcon={
              hasCopied ? (
                <Icons.Check style={{height: "16px", width: "16px", color: "green"}} />
              ) : (
                <Icons.Copy style={{height: "16px", width: "16px"}} />
              )
            }
            sx={{textTransform: "capitalize", color: "black"}}
            variant="outlined"
            onClick={() => {
              copyToClipboard(props.remoteUrl);
              setHasCopied(true);
            }}
          >
            Compartir Link de Pago
          </Button>
        </Container>
      </Grid>
    </Grid>
  );
}
