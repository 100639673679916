import type {ChangeEvent} from "react";

import Image from "next/image";
import Item from "@mui/material/ListItem";
import {
  Container,
  Grid,
  Box,
  FormControl,
  Button,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import {useState} from "react";
import _ from "lodash";
import {v4 as uuidv4} from "uuid";
import dynamic from "next/dynamic";

import useAuth from "../../hooks/useAuth";
import qrCreationService from "../../lib/services/qrCreationService";
import QuotationService from "../../lib/services/quotationService";
import qrPaymentService from "../../lib/services/qrPaymentService";
import {reportError} from "../../lib/sentry";
import ExpiredPix from "../remotePayment/ExpiredPix";
import {encrypt} from "../../lib/utils";

import ChargeAmountCard from "./chargeAmountCard";
import RemotePayment from "./remotePayment";
import {getExchangeRates} from "../../lib/services/exchangeRateService";

const StyledQr = dynamic(() => import("../../components/styledQr"), {
  ssr: false,
});

interface PaymentProps {
  amount: number;
  currency: string;
}

export default function PaymentType(props: PaymentProps) {
  const [comment, setComment] = useState("");
  const [commentError, setCommentError] = useState(false);
  const [commentErrorText, setCommentErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  const [qrSrc, setQrSrc] = useState("");
  const [remote, setRemote] = useState(false);
  const [remoteUrl, setRemoteUrl] = useState("");
  const [error, setError] = useState(false);
  const [brlAmount, setBrlAmount] = useState(0);
  const [usdtAmount, setUsdtAmount] = useState(0);
  const [payment, setPayment] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [failed, setFailed] = useState(false);
  const [qrExpired, setQrExpired] = useState(false);
  const [remotePaymentStore, setRemotePaymentStore] = useState("");
  const {user} = useAuth();

  const onSiteInterfaceId = 1;
  const remoteInterfaceId = 2;

  function handleCommentChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setComment(event.target.value);
  }

  async function computeCardAmounts(): Promise<number> {
    setError(false);
    setQrExpired(false);

    const response = await getExchangeRates(props.currency,props.amount);
    const _brlAmount = response["BRL_TO_PAY"]
    const _usdtAmount = response["USDT_TO_CHARGE"]

    setBrlAmount(_brlAmount);

    setUsdtAmount(_usdtAmount)
    return _brlAmount;
  }

  async function handleOnSitePayment() {
    setLoading(true);
    setPayment(false);
    setProcessing(false);
    setFailed(false);
    setError(false);
    const _brlAmount = await computeCardAmounts();

    setQrSrc("");
    setRemote(false);
    setCommentError(false);
    setCommentErrorText("");
    try {
      const quoteId = await QuotationService.handleQuotation(
        user.email,
        props.amount,
        props.currency,
        comment,
        onSiteInterfaceId,
        "",
      );
      
      let brCode = undefined;
      let pixId = undefined;

      if (quoteId) {
        const result = await qrCreationService.handleQrCreation(
          quoteId,
          _brlAmount,
          props.amount,
          props.currency,
          user.email,
        );

        if (result) {
          brCode = result[0];
          pixId = result[1];
          qrCountdown();
        }
      } else {
        setError(true);
      }
      setLoading(false);
      if (brCode) {
        setQrSrc(brCode);
        const pymtStatus = await qrPaymentService.checkPixPaymentStatus(pixId, 0);

        if (pymtStatus != 3) {
          handlePixStatus(pymtStatus);
        } else {
          setPayment(false);
          setFailed(false);
          setProcessing(true);
          setQrSrc("");
          const newPymtStatus = await qrPaymentService.checkPixPaymentStatus(pixId, 3);

          handlePixStatus(newPymtStatus);
        }
      } else {
        setError(true);
      }
    } catch (error: unknown) {
      reportError(error as Error);
    }
  }

  function handlePixStatus(pymtStatus: number) {
    if (pymtStatus == 1) {
      setProcessing(false);
      setFailed(false);
      setPayment(true);
      setQrSrc("");
    }
    if (pymtStatus == 4) {
      setPayment(false);
      setProcessing(false);
      setFailed(true);
      setQrSrc("");
    }
  }

  async function handleRemotePayment() {
    setPayment(false);
    setProcessing(false);
    setFailed(false);
    setError(false);
    computeCardAmounts();
    setQrSrc("");
    setRemote(false);
    if (_.isEmpty(comment)) {
      setCommentError(true);
      setCommentErrorText("Ingrese alguna referencia del pago");
    } else {
      setLoading(true);
      setCommentError(false);
      setCommentErrorText("");
      try {
        const uuid = uuidv4();
        const quoteId = await QuotationService.handleQuotation(
          user.email,
          props.amount,
          props.currency,
          comment,
          remoteInterfaceId,
          uuid,
        );

        setLoading(false);
        if (quoteId) {
          setRemoteUrl(createRemotePaymentUrl(uuid, quoteId.merchant_name));
          setRemotePaymentStore(quoteId.merchant_name);
          setRemote(true);
        } else {
          setError(true);
        }
      } catch (error: unknown) {
        reportError(error as Error);
      }
    }
  }

  function qrCountdown() {
    setTimeout(invalidateQr, 300000);

    function invalidateQr() {
      setQrExpired(true);
      setQrSrc("");
    }
  }

  function createRemotePaymentUrl(uuid: string, merchant_name: string | undefined) {
    const data = JSON.stringify({
      id: uuid,
      store: merchant_name || "argentina",
    });

    return `${window.location.origin}/pymnt?id=${encrypt(data)}`;
  }

  function retryQuotation() {
    setError(false);
  }

  return (
    <Container sx={{}}>
      <Grid container spacing={0}>
        <Grid item md={6} xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Item>
                <Box sx={{minWidth: "100%"}}>
                  <FormControl fullWidth>
                    <TextField
                      error={commentError}
                      helperText={commentErrorText}
                      id="comment"
                      label="Referencia"
                      onChange={(e) => {
                        handleCommentChange(e);
                      }}
                    />
                  </FormControl>
                </Box>
              </Item>
            </Grid>
            {loading ? (
              <Grid item md={12} xs={12}>
                <Item>
                  <Box sx={{display: "flex", margin: "auto", mt: 5, mb: 5}}>
                    <CircularProgress size={60} />
                  </Box>
                </Item>
              </Grid>
            ) : (
              <Grid container spacing={3}>
                <Grid item md={4} xs={4}>
                  <Item>
                    <Box margin="auto">
                      <Button
                        color="primary"
                        disabled={loading}
                        sx={{
                          mt: 2,
                          color: "#EDEDED",
                          width: {xs: 90, md: 100},
                          height: {xs: 34.75, md: 36.5},
                          fontSize: {xs: "13px", md: "14px"},
                        }}
                        variant="contained"
                        onClick={handleOnSitePayment}
                      >
                        cobrar
                      </Button>
                    </Box>
                  </Item>
                </Grid>
                <Grid item md={4} xs={4}>
                  <Item>
                    <Box margin="auto">
                      <Button
                        color="primary"
                        disabled={loading}
                        sx={{
                          mt: 2,
                          color: "#EDEDED",
                          width: {xs: 90, md: 100},
                          height: {xs: 34.75, md: 36.5},
                          fontSize: {xs: "13px", md: "14px"},
                        }}
                        variant="contained"
                        onClick={handleRemotePayment}
                      >
                        remoto
                      </Button>
                    </Box>
                  </Item>
                </Grid>
                <Grid item md={4} xs={4}>
                  <Item>
                    <Box margin="auto">
                      <Button
                        color="primary"
                        sx={{
                          mt: 2,
                          color: "#EDEDED",
                          width: {xs: 50, md: 100},
                          height: {xs: 34.75, md: 36.5},
                          fontSize: {xs: "10px", md: "14px"},
                        }}
                        variant="contained"
                        onClick={computeCardAmounts}
                      >
                        <svg
                          fill="#ffffff"
                          height="21"
                          id="Capa_1"
                          stroke="#ffffff"
                          version="1.1"
                          viewBox="0 0 377.791 377.791"
                          width="26"
                          xmlSpace="preserve"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <g>
                              {" "}
                              <g>
                                {" "}
                                <g>
                                  {" "}
                                  <path d="M298.672,377.791H79.119c-19.732,0-35.785-16.053-35.785-35.785V35.787C43.334,16.054,59.387,0,79.119,0h219.553 c19.732,0,35.785,16.054,35.785,35.787v306.219C334.457,361.738,318.404,377.791,298.672,377.791z M79.119,15 c-11.461,0-20.785,9.325-20.785,20.787v306.219c0,11.461,9.324,20.785,20.785,20.785h219.553 c11.461,0,20.785-9.324,20.785-20.785V35.787c0-11.462-9.324-20.787-20.785-20.787H79.119z" />{" "}
                                </g>{" "}
                                <g>
                                  {" "}
                                  <g>
                                    {" "}
                                    <g>
                                      {" "}
                                      <path d="M120.33,169.391H87.781c-4.143,0-7.5-3.357-7.5-7.5V129.34c0-4.143,3.357-7.5,7.5-7.5h32.549 c4.143,0,7.5,3.357,7.5,7.5v32.551C127.83,166.033,124.473,169.391,120.33,169.391z M95.281,154.391h17.549V136.84H95.281 V154.391z" />{" "}
                                    </g>{" "}
                                    <g>
                                      {" "}
                                      <path d="M290.01,169.391h-32.549c-4.143,0-7.5-3.357-7.5-7.5V129.34c0-4.143,3.357-7.5,7.5-7.5h32.549 c4.143,0,7.5,3.357,7.5,7.5v32.551C297.51,166.033,294.152,169.391,290.01,169.391z M264.961,154.391h17.549V136.84h-17.549 V154.391z" />{" "}
                                    </g>{" "}
                                    <g>
                                      {" "}
                                      <path d="M176.891,169.391h-32.549c-4.143,0-7.5-3.357-7.5-7.5V129.34c0-4.143,3.357-7.5,7.5-7.5h32.549 c4.143,0,7.5,3.357,7.5,7.5v32.551C184.391,166.033,181.033,169.391,176.891,169.391z M151.842,154.391h17.549V136.84h-17.549 V154.391z" />{" "}
                                    </g>{" "}
                                    <g>
                                      {" "}
                                      <path d="M233.451,169.391H200.9c-4.143,0-7.5-3.357-7.5-7.5V129.34c0-4.143,3.357-7.5,7.5-7.5h32.551 c4.142,0,7.5,3.357,7.5,7.5v32.551C240.951,166.033,237.593,169.391,233.451,169.391z M208.4,154.391h17.551V136.84H208.4 V154.391z" />{" "}
                                    </g>{" "}
                                  </g>{" "}
                                  <g>
                                    {" "}
                                    <g>
                                      {" "}
                                      <path d="M120.33,227.299H87.781c-4.143,0-7.5-3.357-7.5-7.5v-32.551c0-4.143,3.357-7.5,7.5-7.5h32.549 c4.143,0,7.5,3.357,7.5,7.5v32.551C127.83,223.941,124.473,227.299,120.33,227.299z M95.281,212.299h17.549v-17.551H95.281 V212.299z" />{" "}
                                    </g>{" "}
                                    <g>
                                      {" "}
                                      <path d="M290.01,227.299h-32.549c-4.143,0-7.5-3.357-7.5-7.5v-32.551c0-4.143,3.357-7.5,7.5-7.5h32.549 c4.143,0,7.5,3.357,7.5,7.5v32.551C297.51,223.941,294.152,227.299,290.01,227.299z M264.961,212.299h17.549v-17.551h-17.549 V212.299z" />{" "}
                                    </g>{" "}
                                    <g>
                                      {" "}
                                      <path d="M176.891,227.299h-32.549c-4.143,0-7.5-3.357-7.5-7.5v-32.551c0-4.143,3.357-7.5,7.5-7.5h32.549 c4.143,0,7.5,3.357,7.5,7.5v32.551C184.391,223.941,181.033,227.299,176.891,227.299z M151.842,212.299h17.549v-17.551h-17.549 V212.299z" />{" "}
                                    </g>{" "}
                                    <g>
                                      {" "}
                                      <path d="M233.451,227.299H200.9c-4.143,0-7.5-3.357-7.5-7.5v-32.551c0-4.143,3.357-7.5,7.5-7.5h32.551 c4.142,0,7.5,3.357,7.5,7.5v32.551C240.951,223.941,237.593,227.299,233.451,227.299z M208.4,212.299h17.551v-17.551H208.4 V212.299z" />{" "}
                                    </g>{" "}
                                  </g>{" "}
                                  <g>
                                    {" "}
                                    <g>
                                      {" "}
                                      <path d="M120.33,285.205H87.781c-4.143,0-7.5-3.357-7.5-7.5v-32.549c0-4.143,3.357-7.5,7.5-7.5h32.549 c4.143,0,7.5,3.357,7.5,7.5v32.549C127.83,281.848,124.473,285.205,120.33,285.205z M95.281,270.205h17.549v-17.549H95.281 V270.205z" />{" "}
                                    </g>{" "}
                                    <g>
                                      {" "}
                                      <path d="M176.891,285.205h-32.549c-4.143,0-7.5-3.357-7.5-7.5v-32.549c0-4.143,3.357-7.5,7.5-7.5h32.549 c4.143,0,7.5,3.357,7.5,7.5v32.549C184.391,281.848,181.033,285.205,176.891,285.205z M151.842,270.205h17.549v-17.549h-17.549 V270.205z" />{" "}
                                    </g>{" "}
                                    <g>
                                      {" "}
                                      <path d="M233.451,285.205H200.9c-4.143,0-7.5-3.357-7.5-7.5v-32.549c0-4.143,3.357-7.5,7.5-7.5h32.551 c4.142,0,7.5,3.357,7.5,7.5v32.549C240.951,281.848,237.593,285.205,233.451,285.205z M208.4,270.205h17.551v-17.549H208.4 V270.205z" />{" "}
                                    </g>{" "}
                                  </g>{" "}
                                  <g>
                                    {" "}
                                    <g>
                                      {" "}
                                      <path d="M120.33,343.115H87.781c-4.143,0-7.5-3.357-7.5-7.5v-32.551c0-4.143,3.357-7.5,7.5-7.5h32.549 c4.143,0,7.5,3.357,7.5,7.5v32.551C127.83,339.758,124.473,343.115,120.33,343.115z M95.281,328.115h17.549v-17.551H95.281 V328.115z" />{" "}
                                    </g>{" "}
                                    <g>
                                      {" "}
                                      <path d="M290.01,343.115h-32.549c-4.143,0-7.5-3.357-7.5-7.5v-90.459c0-4.143,3.357-7.5,7.5-7.5h32.549 c4.143,0,7.5,3.357,7.5,7.5v90.459C297.51,339.758,294.152,343.115,290.01,343.115z M264.961,328.115h17.549v-75.459h-17.549 V328.115z" />{" "}
                                    </g>{" "}
                                    <g>
                                      {" "}
                                      <path d="M176.891,343.115h-32.549c-4.143,0-7.5-3.357-7.5-7.5v-32.551c0-4.143,3.357-7.5,7.5-7.5h32.549 c4.143,0,7.5,3.357,7.5,7.5v32.551C184.391,339.758,181.033,343.115,176.891,343.115z M151.842,328.115h17.549v-17.551h-17.549 V328.115z" />{" "}
                                    </g>{" "}
                                    <g>
                                      {" "}
                                      <path d="M233.451,343.115H200.9c-4.143,0-7.5-3.357-7.5-7.5v-32.551c0-4.143,3.357-7.5,7.5-7.5h32.551 c4.142,0,7.5,3.357,7.5,7.5v32.551C240.951,339.758,237.593,343.115,233.451,343.115z M208.4,328.115h17.551v-17.551H208.4 V328.115z" />{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>{" "}
                                <g>
                                  {" "}
                                  <g>
                                    {" "}
                                    <path d="M290.01,108.543H87.781c-4.143,0-7.5-3.357-7.5-7.5V38.068c0-4.142,3.357-7.5,7.5-7.5H290.01c4.143,0,7.5,3.358,7.5,7.5 v62.975C297.51,105.186,294.152,108.543,290.01,108.543z M95.281,93.543H282.51V45.568H95.281V93.543z" />{" "}
                                  </g>{" "}
                                  <g>
                                    {" "}
                                    <path d="M266.879,86.467c-4.143,0-7.5-3.357-7.5-7.5V60.143c0-4.143,3.357-7.5,7.5-7.5s7.5,3.357,7.5,7.5v18.824 C274.379,83.109,271.022,86.467,266.879,86.467z" />{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>
                        </svg>
                      </Button>
                    </Box>
                  </Item>
                </Grid>
              </Grid>
            )}
            <Grid item md={12} xs={12}>
              <Item>
                <ChargeAmountCard brlAmount={brlAmount} usdtAmount={usdtAmount} />
              </Item>
            </Grid>
          </Grid>
        </Grid>

        {!_.isEmpty(qrSrc) && !qrExpired && (
          <Grid item md={6} xs={12}>
            <Grid>
              <Container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <StyledQr url={qrSrc} width={300} />
              </Container>
            </Grid>
          </Grid>
        )}
        {qrExpired && _.isEmpty(qrSrc) && !payment && !processing && !failed ? (
          <Grid item md={6} xs={12}>
            <Grid>
              <ExpiredPix />
            </Grid>
          </Grid>
        ) : null}
        {payment && _.isEmpty(qrSrc) && !processing && !failed ? (
          <Grid item md={6} xs={12}>
            <Grid>
              <Container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Image alt="check" height={300} src="/qr-check-green.png" width={300} />
              </Container>
            </Grid>
          </Grid>
        ) : null}
        {processing && _.isEmpty(qrSrc) && !payment && !failed ? (
          <Grid item md={6} xs={12}>
            <Grid>
              <Container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Image alt="processing" height={300} src="/qr-check-green.png" width={300} />
              </Container>
            </Grid>
          </Grid>
        ) : null}
        {failed && _.isEmpty(qrSrc) && !payment && !processing ? (
          <Grid item md={6} xs={12}>
            <Grid>
              <Container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Image alt="failed" height={300} src="/failed.png" width={300} />
              </Container>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      {remote ? (
        <Grid item md={6} xs={12}>
          <Grid>
            <Item>
              <Container
                sx={{
                  display: "flex",
                  justifyContent: {md: "left", xs: "center"},
                  // ml: {md: 0},
                }}
              >
                <RemotePayment remotePaymentStore={remotePaymentStore} remoteUrl={remoteUrl} />
              </Container>
            </Item>
          </Grid>
        </Grid>
      ) : null}
      {error ? (
        <Grid item md={6} xs={12}>
          <Grid>
            <Item>
              <Typography align="center" sx={{mt: 4}} variant="h5">
                Algo salió mal, por favor intente nuevamente
              </Typography>
            </Item>
          </Grid>

          <Grid>
            <Item>
              <Container
                sx={{
                  display: "flex",
                  justifyContent: {md: "left", xs: "center"},
                  ml: {md: 23},
                }}
              >
                <Button
                  color="primary"
                  sx={{
                    mt: 2,
                    color: "#EDEDED",
                    width: 120,
                    align: "center",
                  }}
                  variant="contained"
                  onClick={retryQuotation}
                >
                  Reintentar
                </Button>
              </Container>
            </Item>
          </Grid>
        </Grid>
      ) : null}
    </Container>
  );
}
