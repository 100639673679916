import type {SelectChangeEvent} from "@mui/material";
import type {ChangeEvent} from "react";

import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Hidden from "@mui/material/Hidden";
import Item from "@mui/material/ListItem";
import Container from "@mui/material/Container";
import {useEffect, useState} from "react";
import _ from "lodash";

import PaymentType from "./paymentType";

export default function PaymentAmount() {
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("");
  const [, setAmountError] = useState(false);
  const [, setAmountErrorText] = useState("");

  useEffect(() => {
    const lastCurrency = localStorage.getItem("_currency") || "USDt";
    const curr = lastCurrency;

    setCurrency(curr);
    if (amount == 0) {
      setAmountError(true);
      setAmountErrorText("Ingrese un valor numérico");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleCurrencyChange(event: SelectChangeEvent) {
    const curr = event.target.value as string;

    setCurrency(curr);
    localStorage.setItem("_currency", event.target.value);
  }

  function amountIsValid(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const amount = event.target.value;

    return /^[0-9,.]*$/.test(amount) && Number(amount) !== 0;
  }

  function handleAmountChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (amountIsValid(event)) {
      const amount = _.replace(event.target.value, ",", ".");

      setAmount(Number(amount));
      setAmountError(false);
      setAmountErrorText("");
    } else {
      setAmountError(true);
      setAmountErrorText("Ingrese un valor numérico distinto de cero");
    }
  }

  return (
    <>
      <Container sx={{mt: {xs: 3, md: 0}}}>
        <Grid container spacing={0}>
          <Grid item md={6} xs={12}>
            <Grid container spacing={0}>
              <Grid item md={12} xs={12}>
                <Hidden mdDown>
                  <Typography align="left" sx={{mb: 1.5, ml: 2}} variant="h4">
                    Cobrar
                  </Typography>
                </Hidden>
              </Grid>
              <Grid item md={6} xs={12}>
                <Item>
                  <Box sx={{minWidth: "100%"}}>
                    <FormControl fullWidth>
                      <TextField
                        required
                        id="contact"
                        inputProps={{style: {textAlign: "right"}, pattern: "[0-9]*"}}
                        label="Monto"
                        type="number"
                        onChange={(e) => {
                          handleAmountChange(e);
                        }}
                      />
                    </FormControl>
                  </Box>
                </Item>
              </Grid>
              <Grid item md={6} xs={12}>
                <Item>
                  <Box sx={{minWidth: "100%"}}>
                    <FormControl fullWidth>
                      <InputLabel id="curr-select">Moneda</InputLabel>
                      <Select
                        autoWidth
                        id="curr-select"
                        label="Currency"
                        labelId="curr-select"
                        value={currency}
                        onChange={handleCurrencyChange}
                      >
                        <MenuItem value="ARS">ARS, Peso Argentino</MenuItem>
                        <MenuItem value="BRL">BRL, Real Brasilero</MenuItem>
                        <MenuItem value="USDt">USDt, Tether (USDt)</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <PaymentType amount={amount} currency={currency} />
    </>
  );
}
