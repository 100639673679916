import {Paper, Typography} from "@mui/material";
import _ from "lodash";

interface AmountChargeCardProps {
  brlAmount: number;
  usdtAmount: number;
}

function formatAmount(amount: number) {  
  const str: string = amount.toFixed(2);

  return _.replace(str, ".", ",") as unknown as number;
}

export default function ChargeAmountCard(props: AmountChargeCardProps) {
  return (
    <Paper
      elevation={4}
      sx={{
        width: "100%",
        minHeight: 100,
        mt: {xs: "12px", md: "24px"},
        mb: 2,
      }}
    >
      <Typography align="center" sx={{pt: 2}} variant="h5">
        Monto a cobrar:
      </Typography>
      <Typography
        align="center"
        sx={{
          mt: 2,
          pb: 2,
        }}
        variant="h6"
      >
        {formatAmount(props.brlAmount)} BRL / {formatAmount(props.usdtAmount)} USDt
      </Typography>
    </Paper>
  );
}
